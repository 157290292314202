.chat-history {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: rgb(207, 234, 234);
    gap: 12px;
    height: 80vh; /* or a specific height like 80vh */
    -webkit-overflow-scrolling: touch; /* for iOS */
    touch-action: pan-y; /* for better touch scrolling */
  }
  
  .message {
    max-width: 80%;
    padding: 12px;
    border-radius: 12px;
    position: relative;
  }
  
  .message.user {
    background: #007bff;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 4px;
  }
  
  .message.response {
    background: #f0f0f0;
    color: #333;
    align-self: flex-start;
    border-bottom-left-radius: 4px;
  }
  
  .message-time {
    font-size: 0.7em;
    opacity: 0.7;
    margin-top: 4px;
  }
  