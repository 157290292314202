.chat-input-container {
    padding: 16px;
    background: #f8f9fa;
    border-top: 1px solid #eee;
    border-radius: 0 0 12px 12px;
    display: flex;
    gap: 8px;
  }
  
  .chat-input {
    flex: 1;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;
    font-size: 14px;
  }
  
  .chat-input:focus {
    border-color: #007bff;
  }
  
  .send-button {
    /* background: #007bff; */
    background: linear-gradient(#ff4500, blue);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .send-button:hover {
    transform: scale(1.05);
  }