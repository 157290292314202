.footer-container {
  width: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 2%;
  margin-top: 2%;
  border-top: 1px solid rgb(204, 185, 185);
  padding-top: 3%;
}
.footer-up {
  display: flex;
  flex-wrap: wrap;
  /* background-color: green;
  height: 600px; */
  margin-bottom: 20px;
}

.footer-social-title {
  color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}
.footer-logo-container {
  width: 30%;
  min-height: 200px;
  flex-shrink: 0;
  text-align: center;
  /* overflow: hidden; */
  position: relative;
}
.footer-logo {
  margin-left: 0;
  width: 60%;
  padding: 2%;  
}
.footer-link-section {
  /* width: 200px; */
  width: 150px;
}
.footer-links {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 70%;
  justify-content: space-between;
  padding: 0 5%;
}
.footer-social {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-top: 20px;
}

.footer-link:hover {
  color: #000;
  cursor: pointer;
}
.footer-li-title {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  margin-bottom: 5%;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer-log-text {
  color: #848484;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.footer-bottom {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;  
}

.footer-container hr {
  border: 1px solid #848484;
  width: 100%;
  margin: 0 0 2% 0;
}

.footer-link {
  color: #848484;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
  /* display: inline; */
  
}

.footer-social-link:hover {
  cursor: pointer;

}
@media screen and (max-width: 1000px) {
  .footer-up {
    flex-direction: column;
  }
  .footer-log-text {
    margin-bottom: 1%;
  }
  .footer-logo-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-links {
    width: auto;
    padding: 0;
    
  }  

  .footer-social {
    width: 80px;    
  }

  .footer-container {
    padding: 3% 5%; 
    margin-bottom: 1%;
  }
  
}
