.careers-1-container {
  height: 400px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/careersImage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.careers-1-text {
  color: #fff;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}
.job-card {
  border-radius: 20px;
  padding: 23px;
  border: 1px solid #afafaf;
  background: #fff;
  max-width: 80%;
  margin: 5% auto;
}
.job-card > h2 {
  color: #032149;
  font-family: "archivo-condensed";
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  text-transform: capitalize;
}
.job-card-title-container {
  /* margin-bottom: 10%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.job-card-title {
  color: #032149;
  font-family: "archivo-condensed";
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 200% */
  text-transform: capitalize;
}
.job-card-button {
  width: 184px;
  height: 35px;
  /* display: flex; */
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.job-req-container {
  display: flex;
  flex-wrap: wrap;
}
.job-req {
  font-size: 16px;
  padding: 1.5%;
  margin: 1.5%;
  border-radius: 50px;
  background: #f8f8f8;
  color: #032149;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 880px) {
  .careers-1-container {
    height: 200px;
  }
  .job-req {
    font-size: 12px;
  }
  .job-card-title-container {
    margin-bottom: 10%;
  }
}
