.stocks-table-container {
  width: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  padding: 2%;
}


.stocks-table th,
.stocks-table td {
  padding: 0.5rem;
  text-align: left;
}

.top-stocks-header {  
  justify-content: space-around;
  align-items: center;
  width: 85%;
  color: #000;  
  font-size: 25px;
  font-style: normal;
  font-weight: 900;  
}
.table-header-title {
  width: 240px;
  font-family: "archivo-condensed";
  font-weight: 900;
}
.table-search {
  border: 0.5;
  width: 250px;
  font-size: 16px;
  margin-left: 3%;
}

/* .table-hr {
  height: 5px; 
  background-color: black; 
  border: none; 
} */

.symbol-name {
  display: inline;
}
.symbol-image {
  width: 30px;
  height: 30px;
  display: inline;
  border-radius: 50%;
  margin-right: 3%;
  /* object-fit: cover;  */
  object-fit: contain; /* Ensures the full image is visible */
  object-position: center; /* Centers the image */
  vertical-align: middle;
}
.stocks-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px ;
  /* border-top: 1px grey solid; */
  margin-top: 20px;  
}


.stocks-table {
  position: relative;
}

.stocks-table::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  border-top: 1px solid gray; /* Adjust thickness and color as needed */
}

.table-stocks-symbol{
  /* display: flex; */
  min-width: 110px;
  align-items: center;
  height: 100%;
  align-self: stretch;
  padding: 0 !important;
  font-weight: bold;
  /* background-color: green; */
}
.stock-symbol {
  /* height: 100%;  */
  /* font-weight: bold; */
  /* background-color: green; */
}
.table-stocks-symbol span {
  margin-left: 8px; /* Add spacing between image and text */
}
.table-stocks-symbol :hover{
  cursor: pointer;
}
.stocks-table-container > hr {
  margin-right: 15%;
}



.stocks-table tr {
  border-bottom: 1px solid #e5e7eb;
}
.stocks-table tr:hover {  
  background-color: #c2cbd4;
  cursor: pointer;
}


.stock-price {
  text-align: right;
}

.stock-change {
  
}

.stock-change.positive {
  color: #10b981;  
  text-align: center;
}

.stock-change.negative {
  color: #ef4444;
  text-align: center;
}

.change-icon {
  margin-right: 0.25rem;
}

#th-dc {
  text-align: center;
}

@media screen and (max-width: 1181px) {
  .stocks-table-container {
    width: 100%;    
  }  
    

}