.home-adv-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  padding: 4% 4%;
  justify-content: space-around;
  background: linear-gradient(to bottom, #f5f5f5 50%, white 50%);
}

.home-adv-sc-container {
  border-radius: 15px;
  border: 1px solid #0056d6;
  background: #fff;
  width: 150px;
  height: 160px;
  text-align: center;
  padding: 20px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.home-adv-sc-text {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.home-adv-sc-image {
    width: 40px;
    height: 40px;    
    flex-shrink: 0;
}


@media screen and (max-width: 1181px) {

  .home-adv-container {   
    /* background: linear-gradient(to bottom, green 20%, white 20%); */
    background: linear-gradient(to bottom, #f5f5f5 20%, white 20%);
  }
}