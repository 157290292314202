#header-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #0060df;
  text-align: center;
}

.logged-name {
  font-weight: 700;
}

#header-header-text {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

.header-container {
  width: 100%;
  display: flex;
  padding: 2% 3%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  justify-content: space-between;  
  box-sizing: border-box;
  align-items: center; /* Ensure all items are vertically centered */
}

.header-left-container {
  display: flex;
  align-items: center;
  /* justify-content: flex-start;    */
}

.header-right-container {
  display: flex;
  box-sizing: border-box;
  width: auto;
  min-width: 50%;
  /* flex: 1; */
  justify-content: flex-end;
  /* justify-content: space-between; */
  align-items: center;
}

.header-links {
  display: flex;
  align-items: center;
  justify-content: center;
}


.haq {
  min-width: 200px;
}
.header-right-container-mobile {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the end */
  gap: 20px; /* Add spacing between items */
}

#header-search {
  border: 0;
  width: 150px;
}

.header-link {
  margin-left: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

#header-logo-img {
  max-width: 16%;
}

#header-logo-img:hover {
  cursor: pointer;
}

#header-search-icon {
  cursor: pointer;
}

#header-mobile-search-text {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}

/* Mobile Menu Styling */
.header-mobile-menu-body {
  padding: 20px;
  background-color: #f8f9fa;
}

.header-mobile-menu-item {
  padding: 15px 10px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header-mobile-menu-item:hover {
  background-color: #e9ecef;
}

.header-mobile-menu-item:last-child {
  border-bottom: none;
}



/* Responsive Styles */


@media (min-width: 900px) and (max-width: 1500px) {
  .header-container {
    padding: 1.5% 0.2%;
  }
  .header-link {
    font-size: 16px;
  }
}


@media (max-width: 1270px) {
  .header-container {
    flex-direction: row; /* Keep items in a row */
    align-items: center; /* Center items vertically */
    padding: 1% 5%;
    /* 
    .header-container {
  width: 100%;
  display: flex;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  justify-content: space-between;  
  box-sizing: border-box;
  align-items: center; 
}
    
    */
  }

  #header-logo-img {
    max-width: 50%;
  }
  .header-right-container {
    display: none; /* Hide desktop right container */
  }

  .header-right-container-mobile {
    display: flex; /* Show mobile right container */
    width: auto; /* Allow it to take only required space */
  }

  #header-mobile-search-text {
    display: inline; /* Show search text on mobile */
  }

  .only-pc {
    display: none; /* Hide elements with "only-pc" class on mobile */
  }
}

@media (min-width: 1800px) {
  .header-link {
    font-size: 22px;
  }
  #header-search {
    font-size: 18px; 
    width: 200px;
  }
}
@media (min-width: 1270px) {
  .header-right-container-mobile {
    display: none; /* Hide mobile right container on desktop */
  }

  #header-mobile-search-text {
    display: none; /* Hide search text on desktop */
  }

  .only-mobile {
    display: none; /* Hide elements with "only-mobile" class on desktop */
  }
}