.login-container {
  margin: auto;
  text-align: center;
  padding: 2% 0%;
}

.login-1-container {
  background-image: url("../../assets/login-main-image.png");
  height: auto; /* Change from 100vh to auto */
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh; /* Keep this to ensure it covers at least the viewport height */

}

.login-1-video {
  position: absolute;
  width: 100%;
  height: 100%; /* Ensure the video covers the full height of the parent */
  object-fit: cover;
  z-index: -1;
  top: 0;  
  left: 0;
}

.login-inner-container {
  width: 90%;
  border-radius: 25px;
  /* max-height: 90vh;  */
  margin: auto;
  text-align: center;
  margin-top: 5%; /* Adjust as needed */
  background-color: white;
  padding-top: 5%;
  padding-bottom: 5%;
  overflow-y: auto; 
  margin-bottom: 5%;
}

.login-title {
  max-width: 90%; /* Adjusted for mobile */
  margin: auto;
  margin-top: 10px; /* Adjusted for mobile */
  color: #063f8b;
  text-align: center;
  font-family: "Roboto Condensed";
  /* font-family: "archivo-condensed"; */
  font-size: 2rem; /* Responsive font size */
  font-style: normal;
  font-weight: 800;
  line-height: 1.4; /* Responsive line height */
  text-transform: uppercase;
}

.login-1-text1 {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 1.2rem; /* Responsive font size */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; 
  padding: 3%;
}
.login-1-text2 {
  color: #0060df;
  text-align: center;
  font-family: Roboto;
  font-size: 1.2rem; /* Responsive font size */
  font-style: normal;
  font-weight: 700;
  line-height: 1.5; 
  padding: 3%;
}

.login-boxes-container {
  margin: auto;
  justify-content: center; /* Center items on mobile */
  max-width: 100%; /* Adjusted for mobile */
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Added gap for better spacing */
}

.inner-box-container {
  color: black;
  padding: 4%;
  width: 100%; /* Full width on mobile */
  max-width: 255px; /* Limit max width */
  border-radius: 20px;
  border: 1px solid #848484;
  margin-bottom: 10px; /* Added margin for spacing */
  margin: 0 10px 10px 10px;
}

.login-box-img {
  width: 60px;
  height: 60px;
  padding: 4%;
  border-radius: 50%;
  background-color: #0056d6;
}

.login-box-title {
  color: #000;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 1.2rem; /* Responsive font size */
  font-style: normal;
  font-weight: 800;
  line-height: 1.5; /* Responsive line height */
  text-transform: uppercase;
  margin-top: 9%;
}

.login-box-text {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 1rem; /* Responsive font size */
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* Responsive line height */
}

.login-1-title2 {
  color: #0056d6;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 1.5rem; /* Responsive font size */
  font-style: normal;
  font-weight: 700;
  line-height: 1.5; /* Responsive line height */
  text-transform: uppercase;
}




.login-inner-footer {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5;
  padding: 3%;
}

.lgoin-google-btn {    
  margin: auto;
  display: flex;
  justify-content: center; 
  align-items: center;  
  padding: 0; 
}

.google-auth-container {
  text-align: center;
  width: auto;
  border: 0px rgb(24, 42, 208) solid;
  border-radius: 15px;
  max-width: 300px;
  margin: auto; 

}

.custom-google-button {
  background-color: #4285F4 !important;
  color: white !important;
  /* add other styles with !important */
}


/* Media Queries for Mobile */
@media (max-width: 768px) {
  .login-title {
    font-size: 1.5rem; /* Smaller font size for mobile */
    line-height: 1.2;
  }

  .login-1-text1 {
    font-size: 1.1rem; /* Smaller font size for mobile */
  }

  .login-1-title2 {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }

  .lgoin-google-btn {
    padding: 0 5%; /* Adjusted for mobile */
  }

  .login-boxes-container {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center;
  }
  .login-1-video {
    height: 100%;
  }
}
