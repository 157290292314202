.home-indicators-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 2% 12%;
  padding-top: 7%;
  background: #f5f5f5;
}
.home-indicator {
  background: white;
  width: 200px;
  max-width: 45%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.home-indicator:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.symbol {
  font-weight: bold;
  font-size: 18px;
}

.change {
  font-size: 14px;
  color: #28a745; /* Green for positive change, use red for negative */
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1181px) {
  .home-indicators-container {
    gap: 10px;    
    padding: 2% 2%;
    padding-top: 7%;
  }
  .home-indicator {
    padding: 10px;
  }
  .left-section {    
  }
  
  .symbol {
    font-weight: bold;
    font-size: 17px;
  }
  
  .change {
    font-size: 13px;
    color: #28a745; /* Green for positive change, use red for negative */
  }
  
  .right-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
