.post-payment-container {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
    max-width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .post-payment-container h1 {
    color: #4caf50;
    font-size: 2.5em;
  }
  
  .post-payment-container p {
    color: #555;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .confirm-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #45a049;
  }
  