.home-main-title {
  color: #000;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  text-transform: uppercase;
}

.home-second-title {
  color: #000;
  max-width: 1188px;
  margin: auto;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home-search-container {
  text-align: center;
  padding: 4%;
}
.home-search-input {
  border: 0px;
  width: 307px;
  border-radius: 50px;
  box-shadow: 1px 1px 3px black;
  background-color: white;
  padding-left: 40px;   
  background-image: url("../../assets/searchIcon.png");
  height: 50px;
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-size: 20px 20px;
}

#home-stocks-table-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 40px;
  padding: 2% 10%;
  width: 100%;
  box-sizing: border-box;
}

#home-crypto-table-container {
  padding: 2% 10%;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 1181px) {
  .home-main-title {    
    width: 88%;
    margin: auto;
    font-family: "Roboto Condensed";
    font-size: 36px;
    padding-top: 4%;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; 
    text-transform: uppercase;
    margin-top: 5%;
  }
  .home-second-title {
    font-size: 18px;
    width: 88%;
    /* padding: 2%; */
    margin-top: 7%;
    margin-bottom: 7%;
  }
  #home-stocks-table-container {
    flex-direction: column;
    padding: 5%;
  }
  #home-crypto-table-container {
    flex-direction: column;
    padding: 5%;
  }
}
