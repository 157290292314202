.api-container {
    text-align: center;
    margin: 50px auto;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .api-container h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .api-container p {
    color: #555;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .support-email {
    color: #4a90e2;
    text-decoration: none;
    font-weight: bold;
  }
  
  .support-email:hover {
    text-decoration: underline;
  }
  