
.modal-content2 {
    /* padding: 20px; */
    width: 100%;
    background-color: #f8f9fa;
    border-radius: 10px;
    border: black solid 1px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
.modal-content {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .co-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 50px;
  }
  .co-input {    
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 2%;
  }
  .checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .paypal-buttons-container {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
  
  .loading-text {
    font-size: 18px;
    color: #6c757d;
  }
  
  .modal-header, .modal-footer {
    border: none;
  }
  
  .modal-header .close {
    font-size: 24px;
    padding: 0;
  }
  
  .modal-footer button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-footer button:hover {
    background-color: #0056b3;
  }
  
  .co-prime-title{
    /* text-align: center; */
    font-weight: 700;
    margin-bottom: 4%;
  }
  .co-second-title{
    text-align: center;
    font-weight: 700;
  }
    
  
  .pre-checkout-detail {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .pre-checkout-button {
    background-color: #0070ba;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pre-checkout-button:hover {
    background-color: #005a8e;
  }

  .pp-close-btn-container {
    display: flex;
  }
  .pp-close-btn {
    width: 40px;
    margin-left: 0;
  }
  