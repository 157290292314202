.data-chart-y-axis-label {
    dominant-baseline: middle;
  }

  /* DataChart.css */
.data-chart-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .data-chart-svg {
    width: 100%;
    height: auto;
  }