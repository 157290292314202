.checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.checkout-paypal-container {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
}

.checkout-title {
    font-weight: 700;
    margin-bottom: 15px;
}

.checkout-subtitle {
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

.checkout-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.checkout-textarea {
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px;
    resize: vertical;
}

.checkout-free-message {
    font-size: 16px;
    color: #e91e63;
    font-weight: 600;
    margin: 10px 0;
    text-align: center;
}

.checkout-free-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    width: 280px;
    margin: auto;
}

.checkout-free-btn:hover {
    background-color: #218838;
}

.checkout-free-btn:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}