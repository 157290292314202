.cryptos-table-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}

.cryptos-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cryptos-top-header h2 {
  margin: 0;
  font-size: 24px;
  color: #032149;
}

#cryptos-header-search {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  max-width: 50%;
  font-size: 14px;
}

.cryptos-table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid black;
  margin-top: 20px;
}

.cryptos-table th {
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #eee;
  color: #666;
  font-weight: 600;
}

.cryptos-table td {
  padding: 12px;
  border-bottom: 1px solid #eee;  
}
.cryptos-table tr:hover {
  background-color: #c2cbd4;
  cursor: pointer;
}

.cryptos-symbol {
  display: flex;
  align-items: center;
  cursor: pointer;  
  font-weight: bold;
}

.cryptos-symbol:hover {
  color: #0066cc;
}

.cryptos-symbol-image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}

.cryptos-change {
  align-items: center;
  gap: 4px;
}

.cryptos-change.positive {
  color: #00c853;
}

.cryptos-change.negative {
  color: #ff3d00;
}

.cryptos-change-icon {
  vertical-align: middle;
}

hr {
  border: none;
  border-top: 1px solid #eee;
  margin: 20px 0;
}
