.in-app-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  margin: auto;
  gap: 10px;
}
.in-app-login-intro {
  text-align: center;
  color: blue;
  font-family: roboto;
  font-weight: 700;
}

.in-app-login-title {
  font-size: 24px;
  font-weight: bold;
}

.in-app-login-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.in-app-login-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-error {
  border: 1px solid #f44336;
}

.error-message {
  color: #f44336;
  font-size: 12px;
  margin: 0;
  width: 100%;
  text-align: left;
}

.in-app-login-checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.in-app-login-checkbox {
  margin-right: 8px;
  cursor: pointer;
}

.in-app-login-checkbox:disabled {
  cursor: not-allowed;
}

.in-app-login-checkbox-label {
  font-size: 14px;
  cursor: pointer;
}

.in-app-login-button {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 42px;
}

.in-app-login-button:hover:not(:disabled) {
  background-color: #45a049;
}

.in-app-login-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

.in-app-login-toggle {
  background: none;
  border: none;
  color: #4caf50;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: underline;
}

.in-app-login-toggle:hover:not(:disabled) {
  color: #45a049;
}

.in-app-login-toggle:disabled {
  color: #a5d6a7;
  cursor: not-allowed;
}

/* Spinner styling */
.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

.in-app-register-subtitle {
  text-align: center;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: green;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}