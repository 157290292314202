.chatbot-container {
  position: fixed;
  right: 20px;  
  z-index: 1000;
}

.chatbot-trigger {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  /* background: #007bff; */
  background: linear-gradient(#ff4500, blue);
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.chatbot-trigger:hover {
  transform: scale(1.05);
}

.chatbot-window {
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 60vh; /* or your desired height */
}

.chatbot-header {
  padding: 16px;
  background: #007bff;
  color: white;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-align: right;
}

.chatbot-trigger {
  width: auto; /* Change from 60px */
  padding: 8px 16px;
  gap: 8px;
}

.ask-text {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 480px) {
  .chatbot-container {
    bottom: 140px;
    right: 10px;
  }

  .chatbot-window {
    width: 95vw; /* Slightly larger on very small screens */
    height: 70vh;
    overflow: hidden;
    height: 65vh; /* or your desired height */
  }  
}
