.blue-ta-container {
  display: flex;  
  /* justify-content: space-between; */
  align-items: center;
  margin: 0% 0%;
  padding: 0.5% 5%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; /* Optional: Adjust as needed */
  
  background: radial-gradient(50% 50% at 50% 50%, #0056D6 1.32%, #063F8B 100%);
  color: var(--white);
  position: sticky;
  bottom: 0;  
}

.bta-btn {
  
  border: 1px solid white;
  border-radius: 50px;
  border: 1px solid #fff;
  width: 240px;
  height: 35px;
  flex-shrink: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;  
  justify-content: center;
}
.bta-text1 {
  color: #fff;
  font-family: "archivo-condensed";
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 133.333% */
  text-transform: uppercase;
  color: #fff;
  font-family: "archivo-condensed";
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
}
.bta-text2 {
    font-size: 25px;
}

.bta-text-container {
    width: 46%;
}

.bta-arrows-container {
    font-size: 40px; /* Adjust the size as needed */
    text-align: center;
    margin: auto;
    width: 30%;    
  }
  
  @media (max-width: 768px) {
    .blue-ta-container {
      flex-direction: column;
      padding: 10px;
      text-align: center;
    }
  
    .bta-text1 {
      font-size: 20px;
      line-height: 30px;
    }
  
    .bta-text2 {
      font-size: 16px;
    }
  
    .bta-text-container, .bta-arrows-container {
      width: 100%;
    }
  
    .bta-btn {
      margin-top: 10px;
    }
  }