.ai-container {
  text-align: center;
}
.ai-title-image-container {
  height: 200px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/aiTitleImage.png"); /* Adjust opacity */

  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url("../../assets/aiTitleImage.png") lightgray 50% / cover no-repeat;
  background-size: 110%;
  background-position: center top;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-1-text {
  font-size: 70px;
  color: #fff;
  text-align: center;
  font-family: "";
  font-style: normal;
  font-family: "archivo-condensed";
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  text-transform: uppercase;
}

.ai-2-container {
  text-align: center;
  margin: 5% 12%;
}
.ai-2-title {
  color: #032149;
  text-align: center;
  font-family: "archivo-condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 125% */
  text-transform: capitalize;
}
.ai-2-text {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}

.ai-3-container {
  display: flex;
  /* flex-wrap: wrap; */
  box-sizing: border-box;
  text-align: left;
  background: rgba(242, 242, 242, 0.5);
  justify-content: space-around;
  padding: 3% 12%;
}
.ai-3-img {
  border-radius: 20px;
  width: 570px;
  max-width: 100%;
  /* min-width: 300px; */
  max-height: 380px;
}
.ai-3-right {
  width: 50%;
  padding: 0 20px;
}
.ai-inner-title {
  color: #032149;
  font-family: "archivo-condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 125% */
  text-transform: capitalize;
  text-align: left;
}
.ai-3-txt {
  text-align: left;
  /* max-width: 50%; */
  color: #848484;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}
.ai-4-container {
  color: #fff;
  text-align: center;
  background: linear-gradient(276deg, #0056d6 0%, #032149 100%);
  padding: 3% 10%;
}

.ai-5-container {
  display: flex;
  color: #848484;
  padding: 3% 12%;
}
.ai-5-inner-text {
  text-align: left;
  margin: 4% 4% 4% 0;
  font-family: Roboto;
  font-size: 20px;
}

.ai-6-container {
  background-color: black;
  width: 100%;
  /* height: 400px; */
  height: auto;
  padding: 5%;
  background-image: url("../../assets/ai-image-3.png");

  background-size: cover;
  background-size: auto 140%;
  background-position: center;

  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
}
.ai-6-text {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
}

@media screen and (max-width: 1181px) {
  .ai-container {
    margin: 2%;
  }
  .ai-title-image-container {
    height: 200px;
  }
  .ai-3-txt {
    text-align: center;
  }
  .ai-1-text {
    font-size: 55px
  }
  .ai-5-inner-text {
    text-align: center;
  }
  .ai-inner-title {
    text-align: center;
  }
  .ai-title-image-container {
    background: 
    /* linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ), */ url("../../assets/aiTitleImage.png")
      lightgray 30% / cover no-repeat;
    /* background-size: 280%; */

    /* height: 350px; */
    flex-shrink: 0;
    width: 100%;
  }
}
