.google-login-button {
    display: flex;
    flex-direction: row-reverse;
    width: 345px;
    /* max-width: 90%; */
    height: 60px;
    padding: 10px 38px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 50px;
    background: linear-gradient(90deg, #0056D6 0%, #002D70 100%);
    border: none;
    cursor: pointer;
  }
  
  .google-login-button-text {
    color: #FFF;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .google-login-button-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    padding: 2%;
  }


  @media (max-width: 768px) {
    .google-login-button-text {
        font-size: 16px;
    }
    .google-login-button {
        width: 321;
        max-width: 99%;
    }
  }