.about-container {
  text-align: center;
}
.about-title-image-container {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/aboutTitleImage.jpg"); /* Adjust opacity */

  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}
.about-title {
  font-size: 70px;
  color: #fff;
  text-align: center;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  text-transform: uppercase;
}

.about-1-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-align: left;
  /* background: rgba(242, 242, 242, 0.50); */

  justify-content: space-around;
  padding: 3% 8%;
  align-items: center;
  color: #848484;
}
.about-1-left {
  width: 42%;
}
.about-1-title {
  color: #032149;
  font-size: 35px;
  font-style: normal;
  text-transform: capitalize;
  font-family: "Roboto Condensed";
  font-weight: 900;
  line-height: 50px;
}
.about-1-container ul {
  list-style-type: none;
}
.about-1-container li {
  font-size: 20px;
  position: relative;
  padding-left: 25px; /* Space for the icon */  
}
.about-1-container li::before {
  content: '✓'; /* Unicode checkmark */
  font-weight: 900;
  position: absolute;
  left: 0;
  color: green; /* Customize as needed */
}

.about-1-img {
  border-radius: 20px;
  /* width: 570px; */
  width: 541px;
  max-width: 100%;
  /* min-width: 300px; */
  /* max-height: 380px; */
  height: 557px;
}

.about-1-container p {
  font-size: 20px;
  color: #848484;
  font-family: Roboto;
}

.about-hiw-pc {
  width: 100%;
}

.about-wwo-container {
  padding: 3% 10% 5% 10%;
  color: #032149;
}
.about-wwo-container h3 {
  color: #032149;
  text-align: center;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #032149;
  font-family: "archivo-condensed";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 111.111% */
  /* letter-spacing: 0.9px; */
  margin-bottom: 3%;
}
.about-wwo-container h4 {
  color: #032149;
  text-align: center;
  /* font-family: "Archivo Narrow"; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
}
.about-wwo-container p {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}

.about-2-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-align: left;
  justify-content: space-around;
  padding: 3% 8%;
  align-items: center;
}
.about-2-img {
  border-radius: 20px;
  /* width: 570px; */
  width: 541px;
  max-width: 100%;
  /* min-width: 300px; */
  /* max-height: 380px; */
  height: 557px;
}
.about-2-right {
  width: 42%;
  color: #848484;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-2-title {
  color: #032149;
  font-family: "archivo-condensed";
  /* letter-spacing: -1px; Reduce spacing */
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 142.857% */
  text-transform: capitalize;
}

.about-wbb-container {
  background: rgba(242, 242, 242, 0.5);
  padding: 84px 138px;
}
.about-wbb-container h3 {
  color: #032149;
  text-align: center;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #032149;
  font-family: "archivo-condensed";
  /* letter-spacing: -0.5px; Reduce spacing */
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 111.111% */
  /* letter-spacing: 0.9px; */
}
.about-wbb-container p {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}

.about-footer-container {
  padding: 80px 312px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  /* background: url("../../assets/about-footer.jpg") ; */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/about-footer.jpg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 120%; /* Zooms in */
  background-position: center center; /* Centers the zoomed image */
}
.about-footer-container h3 {
  color: #fff;
  text-align: center;
  font-family: "archivo-condensed";
  /* letter-spacing: -2px; Reduce spacing */
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 125% */
  text-transform: capitalize;
}
.about-footer-container p {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
}
.about-footer-container button {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 194px;
  height: 35px;
}

/* ************************** */

@media screen and (max-width: 1181px) {
  .about-title-image-container {
    height: 200px;
  }
  .about-title {
    font-size: 55px;
    font-family: "archivo-condensed";
  }
  .about-1-container {
    width: 100;
  }
  .about-1-container p {
    font-size: 16px;
  }
  .about-1-left {
    width: 100%;
  }
  .about-1-title {
    font-size: 30px;
  }
  .about-1-img {
    width: 100%;
    height: auto;
  }
  .about-wwo-container {
    text-align: left;
  }
  .about-wwo-container h3 {
    color: #032149;
    font-family: "archivo-condensed";
    /* letter-spacing: -0.5px; Reduce spacing */
    text-align: left;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px; /* 166.667% */
  }
  .about-wwo-container h4 {
    text-align: left;
    color: #032149;
    font-family: "archivo-condensed";
    /* letter-spacing: -0.5px; Reduce spacing */
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 27px; /* 135% */
    text-transform: uppercase;
  }
  .about-wwo-container p {
    color: #848484;
    text-align: left;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 168.75% */
  }
  .about-2-right {
    width: 100%;
  }
  .about-2-title {
    font-size: 30px;
  }
  .about-wbb-container {
    padding: 5%;
    text-align: left !important;
  }

  .about-wbb-container h3 {
    text-align: left;
    font-size: 30px;
  }
  .about-wbb-container p {
    text-align: left;
    font-size: 16px;
  }
  .about-footer-container h3 {
    font-size: 30px;
  }
  .about-footer-container p {
    font-size: 20px;
  }
  .about-footer-container {
    padding: 50px 40px;
  }
}
