body {
  margin: 0;
}
:root {
  --blue1: #0056d6;
  --white: #ffffff;
  --pc-min-screen: 1200;
}
@font-face {
  font-family: 'archivo-condensed';
  src: url('./fonts/Archivo_Condensed-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
#site-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}
button {
  width: 143px;
  border-radius: 50px;
  flex-shrink: 0;
  background-color: var(--blue1);
  color: var(--white);
  padding: 1%;
  border: 0;
}
button:disabled {
  background-color: grey;
}
button:disabled:hover {
  cursor: not-allowed;
  background-color: grey;
}
#pre-checkout-non-register-msg {
  font-size: 12px;
  color: red;
}

.blue-text {
  color: #0056D6;
}
.gray-bg {
  background: #f5f5f5;
}

.inner-pages-title {
  font-size: 70px;
}

.unclickable {
  pointer-events: none;
}

.only-mobile {
}
.only-pc {
}

/* @media only screen and (min-width: 1270px){ */
@media only screen and (min-width: var(--pc-min-screen)) {
  .only-mobile {
    display: none !important;
  }

  .inner-pages-title {
    font-size: 55px;
  }
}
@media only screen and (max-width: var(--pc-min-screen)) {
  .only-pc {
    display: none !important;
    width: 0;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
